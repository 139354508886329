<template>
  <div class="tip-box">
    <div class="imgifo" @mouseover="showTooltip" @mouseleave="hideTooltip">
      <img class="information1" src="/assets/sabre-img/information.png" alt="information">
      <img class="information2" src="/assets/sabre-img/information-hover.png" alt="information-hover">
    </div>
    <div class="tip" :class="{'d-block' : isShowTooltip}" @mouseover="showTooltip" @mouseleave="hideTooltip">
      <h5>סופ״ש</h5>
      <p>ימי חמישי עד ראשון</p>
      <h5>אמצ״ש</h5>
      <p>ימי ראשון עד חמישי</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowTooltip: false,
    };
  },
  methods: {
    showTooltip() {
      this.isShowTooltip = true;
    },
    hideTooltip() {
      this.isShowTooltip = false;
    },
  },
};
</script>
